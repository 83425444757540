



























































































































import { Vue, Component, Ref } from 'vue-property-decorator'
import Map from './components/Map.vue'
import TotalCard from './components/TotalCard.vue'
import ChartCard from './components/ChartCard.vue'
import * as charts from './charts'

type TrendType = 'day' | 'week' | 'month'
type EventRow = { value: number; label: string; name: string; description: string; path: string }
type Loading = {
  [name: string]: boolean
}
@Component({ components: { Map, TotalCard, ChartCard } })
export default class DataVisualization extends Vue {
  @Ref() container: HTMLDivElement
  @Ref() trendsChart: HTMLDivElement
  @Ref() totalsChart: HTMLDivElement
  @Ref() growthChart: HTMLDivElement

  screen = { scale: 1, width: 1920, height: 1080 }
  statistic = { total: 0, migrant: 0, permanent: 0, growth: 0, room: 0, event: 0 }

  trendType: TrendType = 'day'
  eventsTableData: EventRow[] = []
  roomsTableData: AnyObj[] = []
  devicesTableData: AnyObj[] = []

  loading: Loading = {
    devicesTable: true,
    roomsTable: true,
    eventsTable: true,
    trendsChart: true,
    totalsChart: true,
    growthChart: true
  }
  fullscreen = false

  get title() {
    return this.$store.state.dashboard.title || ''
  }

  mounted() {
    this.resize()
    this.refresh()

    document.onfullscreenchange = () => {
      this.fullscreen = !!document.fullscreenElement
    }
  }

  navigateTo(url: string) {
    this.$router.push(url)
  }
  resetLoading() {
    this.loading = {
      devicesTable: true,
      roomsTable: true,
      eventsTable: true,
      trendsChart: true,
      totalsChart: true,
      growthChart: true
    }
  }
  resize() {
    const resize = () => {
      const { clientWidth, clientHeight } = document.documentElement
      const ratio = clientWidth / clientHeight
      const designWidth = 1920
      const designHeight = 1080
      const designRatio = designWidth / designHeight

      const scale = ratio > designRatio ? clientHeight / designHeight : clientWidth / designWidth

      this.screen.scale = scale
      this.screen.width = clientWidth / scale
      this.screen.height = clientHeight / scale
    }

    addEventListener('resize', resize)
    resize()
  }

  percent(v: number) {
    return Math.round(v * 100) + '%'
  }

  requestFullscreen() {
    const element = document.documentElement

    if (document.fullscreenEnabled && element.requestFullscreen) {
      element.requestFullscreen()
    }
  }

  exitFullscreen() {
    if (document.fullscreenElement) {
      document.exitFullscreen()
    }
  }

  async refresh() {
    charts.clear(this.trendsChart)
    charts.clear(this.totalsChart)
    charts.clear(this.growthChart)

    this.eventsTableData = []
    this.roomsTableData = []
    this.devicesTableData = []
    this.resetLoading()
    this.statistic = { total: 0, migrant: 0, permanent: 0, growth: 0, room: 0, event: 0 }

    this.renderCharts()
    this.renderTables()
  }

  renderCharts() {
    this.renderTrendsChart()
    this.renderTotalsChart()
    this.renderGrowthChart()
  }

  renderTables() {
    this.renderEventsTable()
    this.renderRoomsTable()
    this.renderDevicesTable()
  }
  cancelLoading(name: string) {
    this.loading[name] = false
  }
  async renderTrendsChart() {
    const { data } = await this.$api.europa.getPersonTrend({ type: this.trendType, cycle: 12 })

    if (data.data) {
      const nums: AnyObj[] = data.data

      charts.init(this.trendsChart, {
        grid: { top: 25, bottom: 50, left: '16%' },
        yAxis: { scale: true },
        xAxis: {
          boundaryGap: false,
          data: nums.map(i =>
            this.trendType === 'day' ? this.$moment(i.date).format('MM.DD') : i.date
          )
        },
        series: [{ type: 'line', data: nums.map(i => i.num) }]
      })
    }
    this.cancelLoading('trendsChart')
  }

  async renderTotalsChart() {
    const { data } = await this.$api.europa.getPersonRealtimeNumber()

    if (data.data) {
      const nums: AnyObj[] = data.data.realtimeNum

      const reduce = (fn: (v: AnyObj) => number) => nums.reduce((a, b) => a + fn(b), 0)

      this.statistic = {
        ...this.statistic,
        total: reduce(v => v.total),
        migrant: reduce(v => v.migrantNum),
        permanent: reduce(v => v.permanentNum)
      }

      charts.init(this.totalsChart, {
        grid: { left: '15%' },
        legend: { data: [{ name: '常住人口' }, { name: '流动人口' }] },
        xAxis: { data: nums.map(i => i.areaName) },
        series: [
          {
            type: 'bar',
            name: '常住人口',
            stack: 'population',
            label: { show: false },
            itemStyle: { color: '#14E6AA' },
            data: nums.map(i => i.permanentNum)
          },
          {
            type: 'bar',
            name: '流动人口',
            stack: 'population',
            label: { show: false },
            itemStyle: { color: '#5B8FF9' },
            data: nums.map(i => i.migrantNum)
          },
          {
            name: '总计',
            type: 'bar',
            legend: { show: false },
            z: -1,
            barGap: '-100%',
            data: nums.map(i => i.total)
          }
        ]
      })
    }
    this.cancelLoading('totalsChart')
  }

  async renderGrowthChart() {
    const { data } = await this.$api.europa.getPersonMouthNumber()

    if (data.code === 0) {
      this.statistic.growth = data.count

      const nums: AnyObj[] = data.data

      charts.init(this.growthChart, {
        grid: { top: 25, left: '15%' },
        xAxis: { data: nums.map(i => i.areaName) },
        series: [
          {
            type: 'bar',
            itemStyle: { color: '#14E6AA' },
            data: nums.map(({ num }) => ({
              value: num,
              label: { position: num > 0 ? 'top' : 'bottom' },
              itemStyle: { color: num > 0 ? '#CD5374' : '#1FB787' }
            }))
          }
        ]
      })
    }
    this.cancelLoading('growthChart')
  }

  async renderEventsTable() {
    const tableData = [
      { name: 'overcrowd', label: '群租预警', description: '当前', path: '/event/overcrowd' },
      { name: 'temperature', label: '温度异常', description: '当月', path: '/event/temperature' },
      { name: 'solitary', label: '老年人关怀', description: '当前', path: '/event/solitary' },
      { name: 'inactive', label: '不活跃租客', description: '当前', path: '/event/inactive' }
    ]

    const { data } = await this.$api.europa.getAnomalousEvents({
      startTime: this.$moment().startOf('month'),
      endTime: this.$moment().endOf('day')
    })

    if (data.code === 0) {
      this.eventsTableData = tableData
        .map(item => ({ ...item, value: data.data[item.name] }))
        .sort((a, b) => b.value - a.value)
      this.statistic.event = this.eventsTableData.reduce((a, b) => a + b.value, 0)
    }
    this.cancelLoading('eventsTable')
  }

  async renderRoomsTable() {
    const { data } = await this.$api.europa.getRoomStatistic()

    if (data.data) {
      this.roomsTableData = data.data.statistics
      this.statistic.room = this.roomsTableData.reduce((a, b) => a + b.count, 0)
    }
    this.cancelLoading('roomsTable')
  }

  async renderDevicesTable() {
    const { data } = await this.$api.europa.getLocationStatistic()

    if (data.data) {
      this.devicesTableData = data.data
    }
    this.cancelLoading('devicesTable')
  }

  handleEventsRowClick({ path }: EventRow) {
    this.$router.push(path)
  }
}
