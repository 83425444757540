






import { Vue, Component, Ref } from 'vue-property-decorator'
import { treeToFlatArray } from 'operation-tree-node'

@Component
export default class Map extends Vue {
  @Ref() map: HTMLDivElement
  @Ref() mapBox: HTMLDivElement

  geoRegex = /^geo:(-?(\d{1,2}(\.\d+)?|1[0-7]\d(\.\d+)?|180)),(-?(\d(\.\d+)|[1-8]\d(\.\d+)?|90))$/
  chart: any = {}
  get areas() {
    return this.$store.state.areas.areas
  }

  async mounted() {
    if (this.areas.length === 0) {
      await this.$store.dispatch('areas/fetchAreas')
    }

    this.registerMaps().then(() => this.renderMap())
  }

  //获取mapBox宽高并赋值给map
  mapBoxResize(mapBox: HTMLDivElement, map: HTMLDivElement) {
    function getStyle(el: HTMLDivElement) {
      return window.getComputedStyle(el)
    }
    const wi = getStyle(mapBox).width
    const hi = getStyle(mapBox).height
    map.style.width = wi
    map.style.height = hi
  }

  // 地图重新渲染函数，重新渲染分为两步：重新设定map容器（不是mapBox，是map）的宽高、调用map实例resize方法
  mapResize() {
    if (this.mapBox && this.map) {
      this.mapBoxResize(this.mapBox, this.map)
    }
    if (this.chart.resize) {
      this.chart.resize()
    }
  }

  activated() {
    this.mapResize()
  }

  registerMaps() {
    return Promise.all([
      this.$api.static.getGeoJson(110000).then(({ data }) => echarts.registerMap('Beijing', data)),
      this.$api.static.getGeoJson(100020).then(({ data }) => echarts.registerMap('Chaoyang', data))
    ])
  }

  async renderMap() {
    // 首次渲染时将mapBox宽高赋值给map，后续通过resize调用该方法
    this.mapBoxResize(this.mapBox, this.map)
    this.chart = echarts.init(this.map, null, { renderer: 'svg' })

    addEventListener('resize', this.mapResize)

    this.chart.setOption({
      geo: [
        {
          show: true,
          map: 'Beijing',
          center: [116.496, 40.036],
          zoom: 4,
          itemStyle: {
            areaColor: 'rgba(26, 35, 44, 0.6)',
            borderColor: 'rgba(45, 61, 68, 1)',
            borderWidth: 1.6
          },
          silent: true
        },
        {
          show: true,
          map: 'Chaoyang',
          center: [116.4896, 40.035],
          zoom: 0.741,
          itemStyle: {
            areaColor: 'rgba(16, 142, 233, 0.5)',
            borderColor: 'rgba(16, 142, 233, 1)',
            borderWidth: 1.6
          },
          silent: true
        }
      ],

      tooltip: {
        triggerOn: 'click'
      },

      series: [
        {
          name: 'sans',
          type: 'scatter',
          coordinateSystem: 'geo',
          label: {
            show: true,
            position: 'top',
            color: '#FFF',
            formatter: (params: AnyObj) => params.data.name
          },
          itemStyle: {
            opacity: 1
          },
          tooltip: {
            show: true,
            trigger: 'item',
            borderWidth: 0,
            position: (
              point: [number, number],
              params: AnyObj,
              dom: HTMLElement,
              rect: { x: number; y: number; width: number; height: number },
              size: { viewSize: [number, number]; contentSize: [number, number] }
            ) => {
              return [
                rect.x - size.contentSize[0] / 2 + rect.width / 2,
                rect.y - size.contentSize[1] - 30
              ]
            },
            formatter: (params: AnyObj, ticket: string, callback: Function) => {
              const { id, name } = params.data

              this.fetchStatistic(id).then(statistic => {
                const { house, person, device } = statistic

                callback(
                  ticket,
                  `<div class="area-tooltip">
                    <h4 class="title">${name}</h4>

                    <div class="statistic">
                      <div class="statistic-item">
                        <div class="label">实有人口</div>
                        <div class="number">${person}</div>
                      </div>
                      <div class="statistic-item">
                        <div class="label">实有房屋</div>
                        <div class="number">${house}</div>
                      </div>
                      <div class="statistic-item">
                        <div class="label">现有设备</div>
                        <div class="number">${device}</div>
                      </div>
                    </div>
                  </div>`
                )
              })

              return '加载中...'
            },
            extraCssText: 'background: rgba(42, 53, 90, 0.9); color: #00a2d8;'
          },
          symbol: 'image://' + require('@/assets/images/statistic/map-marker.svg'),
          symbolSize: [38, 38],
          symbolOffset: [0, '-50%'],
          geoIndex: 0,
          data: this.getAreas()
        }
      ]
    })
  }

  getAreas() {
    const areas = treeToFlatArray<AnyObj, AnyObj>(this.areas, undefined, {
      children: 'children'
    }).filter(({ level, mapLabel }: any) => level === 1 && this.geoRegex.test(mapLabel))

    return areas.map(({ id, name, mapLabel }) => {
      const location = (mapLabel as string)
        .replace('geo:', '')
        .split(',')
        .map(num => parseFloat(num))

      return { id, name, value: location }
    })
  }

  async fetchStatistic(areaId: string) {
    const statistic = { house: 0, person: 0, device: 0 }

    const fetchHouses = async () => {
      const { data } = await this.$api.europa.getHouseStatistic({ areaIds: [areaId] })

      if (data.code === 0) {
        const { count, occupy } = data.data

        statistic.house = count ?? 0
        statistic.person = occupy ?? 0
      }
    }

    const fetchEquipments = async () => {
      const { data } = await this.$api.base.getDevicesSearch({
        areaIds: [areaId],
        cascade: true,
        limit: 1
      })

      if (data.code === 0) {
        statistic.device = data.count ?? 0
      }
    }

    await Promise.all([fetchHouses(), fetchEquipments()])

    return statistic
  }
}
