import _ from 'lodash'

const merge = (object: AnyObj, source: AnyObj) =>
  _.mergeWith(object, source, (value, srcValue, key) => {
    if (key === 'series' && _.isArray(value) && _.isArray(srcValue)) {
      return srcValue.map(srcItem =>
        _.merge(
          {},
          value.find(item => item.type === srcItem.type),
          srcItem
        )
      )
    }
  })

export const init = (el: HTMLElement, option: AnyObj) => {
  const common = {
    aria: {
      enabled: true,
      decal: {
        show: true,
        decals: {
          symbol: 'rect',
          dashArrayX: [1, 0],
          dashArrayY: [2, 5],
          rotation: 0.5235987755982988
        }
      }
    },
    textStyle: { color: '#00A0D8' },
    legend: {
      show: true,
      top: 10,
      left: 10,
      itemWidth: 8,
      itemHeight: 8,
      textStyle: { color: '#FFFFFF' }
    },
    tooltip: {
      show: true,
      trigger: 'axis',
      axisPointer: { lineStyle: { color: '#00A2D8' } },
      backgroundColor: '#2A355A',
      borderColor: '#2A355A',
      textStyle: { color: '#FFFFFF' }
    },
    xAxis: {
      type: 'category',
      boundaryGap: true,
      axisLabel: { interval: 0, rotate: -45 },
      axisLine: { lineStyle: { color: '#00A2D8' } }
    },
    yAxis: {
      type: 'value',
      minInterval: 1,
      splitLine: { lineStyle: { color: '#00A2D8', type: 'dashed' } }
    },
    series: [
      {
        type: 'line',
        // label: { show: true, color: '#18FFBF' },
        lineStyle: { color: '#18FFBF' },
        itemStyle: { color: '#18FFBF' },
        areaStyle: {
          opacity: 0.9,
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              { offset: 0, color: '#15A87F' },
              { offset: 1, color: '#006D75' }
            ]
          }
        }
      },
      {
        type: 'bar',
        // label: { show: true, color: '#FFFFFF', position: 'top' },
        itemStyle: { opacity: 0.8 }
      }
    ]
  }

  const chart = echarts.init(el, null, { renderer: 'svg' })

  chart.clear()
  chart.setOption(merge(common, option))
}

export const clear = (el: HTMLElement) => {
  const chart = echarts.init(el, null, { renderer: 'svg' })

  chart.clear()
}
