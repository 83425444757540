











import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class TotalCard extends Vue {
  @Prop() icon: string
  @Prop() label: string
  @Prop() value: number
}
