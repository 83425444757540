














import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class ChartCard extends Vue {
  @Prop() title: string
  @Prop() loading: boolean
}
